<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="6"></ucMenu>
					<div class="right_con">
						<div class="setting_main" v-if="users.pid==0">
							<p class="fb">个人信息</p>
							<div class="setiting_wrap">
								<div class="setting_line">
									<p class="txt">当前手机号：</p>
									<p class="con">{{phone_show}}<i class="iconfont icon-edit" @click.self="is_phone = true"></i></p>
								</div>
								<div class="setting_line">
									<p class="txt">登录密码：</p>
									<p class="con">***********<i class="iconfont icon-edit" @click.self="is_password = true"></i></p>
								</div>
								<div class="setting_line">
									<p class="txt">开启自动采购：</p>
									<p class="con">
										<el-switch
											v-model="autoPurchase"
											active-color="#13ce66"
											inactive-color="#ccc"
											@change="autoPurchaseChange()">
										</el-switch>
									</p>
								</div>
								<div class="setting_line">
									<p class="txt">接收公众号提醒：</p>
									<p class="con">
										<el-switch
											v-model="switchTab"
											active-color="#13ce66"
											inactive-color="#ccc"
											@change="switchChange()">
										</el-switch>
									</p>
								</div>
								
								<!-- <div class="setting_line">
									<p class="txt">开启官方解密：</p>
									<p class="con">
										<el-switch
											v-model="is_decrypt"
											active-color="#13ce66"
											inactive-color="#ccc"
											:active-value="1"
											:inactive-value="0"
											@change="decryptSwitch()">
										</el-switch>
									</p>
								</div> -->
								
								<!-- <div class="setting_line" v-if="show_auto_print==1">
									<p class="txt">开启自动打单：</p>
									<p class="con">
										<el-switch
											v-model="is_autoprint"
											active-color="#13ce66"
											inactive-color="#ccc"
											:active-value="1"
											:inactive-value="0"
											@change="autoprintSwitch()">
										</el-switch>
									</p>
								</div> -->
								<!-- <div class="setting_line" v-if="is_autoprint==1||true">
									<p class="txt">配置打单商品：</p>
									<div class="con">
										<el-button type="text" size="mini" @click.stop="dialog.printProductDialog = true">显示配置</el-button>
									</div>
								</div> -->
								
							<!-- 	<div class="setting_line">
									<p class="txt">您的解密到期时间：</p>
									<p class="con">
										{{decrypt_expire_time}}
									</p>
								</div> -->
								<!-- <div class="setting_line">
									<p class="txt">您的可用解密额度：</p>
									<p class="con">
										{{decrypt_num}} 
										<span class="btn_log" @click="showDecryptLog()" v-if="auto_print_order==0">查看日志</span>
										<span class="btn_log" @click="is_chongzhi = true" v-if="auto_print_order==1&&orderPrintFee>0">充值额度</span>
									</p>
									
								</div> -->
							</div>
						</div>
						<el-empty description="暂无权限！" v-else></el-empty>
					</div>
				</div>
				<div :class="is_phone?'phone_mask active' : 'phone_mask'" @click.self="is_phone = false">
					<div class="phone_main">
						<i class="iconfont icon-guanbi" @click.self="is_phone = false"></i>
						<p class="title">修改手机号</p>
						<el-input v-model="phone_code" placeholder="输入验证码" class="el_input el_in2">
							<p slot="prefix" class="txts">原手机验证码</p>
							<!-- <p slot="suffix" class="getcode">获取验证码</p> -->
							<p slot="suffix" class="getcode" @click="getPhoneCode('3')">{{is_get_phone_code?phone_second+'s重新获取':'获取验证码'}}</p>
						</el-input>
						<p class="tips">验证码将发送至登录手机号{{phone_show}}</p>
						<el-input v-model="new_phone" placeholder="输入新手机号" class="el_input el_in2">
							<p slot="prefix" class="txts">新手机号码</p>
						</el-input>
						<div class="btn_wrap">
							<p class="btn" @click.self="is_phone = false">取消</p>
							<p class="btn" @click="changePhone()">确定</p>
						</div>
					</div>
				</div>
				<div :class="is_password?'password_mask active' : 'password_mask'" @click.self="is_password = false">
					<div class="password_main">
						<i class="iconfont icon-guanbi" @click.self="is_password = false"></i>
						<p class="title">修改密码</p>
						<el-input v-model="old_pwd" placeholder="输入密码" class="el_input el_in2">
							<p slot="prefix" class="txts">原登录密码</p>
						</el-input>
						<el-input v-model="new_pwd" placeholder="输入新密码" class="el_input el_in2">
							<p slot="prefix" class="txts">新密码</p>
						</el-input>
						<el-input v-model="new_pwd_confirm" placeholder="再次输入确密码" class="el_input el_in2">
							<p slot="prefix" class="txts">确认密码</p>
						</el-input>
						<div class="btn_wrap">
							<p class="btn" @click.self="is_password = false">取消</p>
							<p class="btn" @click="changePwd()">确定</p>
						</div>
					</div>
				</div>
			</div>
			<!-- 解密次数使用日志 -->
			<el-dialog title="解密次数使用日志" :visible.sync="dialog.decryptLogDialog" width="70%" center >
				<decryptLog @close="dialog.decryptLogDialog = false" :show.sync="dialog.decryptLogDialog"></decryptLog>
			</el-dialog>
			<!-- 充值打单次数 -->
			<el-dialog class="chongzhi_mask" :visible.sync="is_chongzhi" width="400px">
				<div class="chongzhi_main" v-loading="is_payment">
					<p class="title"><img class="icon" src="../../assets/images/zhifubao.png" />支付宝扫码充值</p>
					<div class="input_line">
						<p class="txt">订单号：</p>
						<el-input class="in" placeholder="输入支付订单编号" v-model="paymentNum"></el-input>
					</div>
					<div class="input_line explain">注：{{orderPrintFee}}元/次，请充值{{orderPrintFee}}的倍数</div>
					<img class="code" :src="paymentCodeImg" />
					<div class="btn_wrap">
						<p class="btn" @click.self="is_chongzhi = false">取消</p>
						<p class="btn" @click="bindRecharge()">确定</p>
					</div>
				</div>
			</el-dialog>
			<!-- 配置打单商品 -->
			<el-dialog class="chongzhi_mask" :visible.sync="dialog.printProductDialog" width="400px">
				<div class="chongzhi_main" v-loading="is_payment">
					<p class="title">配置打单商品</p>
					<div class="input_line">
						<div class="print_product">
							<el-radio-group v-model="print_product_type">
								<el-radio :label="1">指定无需解密</el-radio>
								<el-radio :label="2">指定只解密</el-radio>
							</el-radio-group>
							<el-input rows="5" type="textarea" placeholder="多个请用','隔开" v-model="print_product_ids"></el-input>
						</div>
					</div>
					
					<div class="btn_wrap">
						<p class="btn" @click.self="dialog.printProductDialog = false">取消</p>
						<p class="btn" @click="bindPrintProduct()">确定</p>
					</div>
				</div>
			</el-dialog>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';
import decryptLog from '../../components/decryptLog/decryptLog';
import common from '../../assets/js/utils/common';
export default {
	name: "ucenterSetting",
	data() {
		return {
			is_phone:false,
			is_password:false,
			switchTab: true,
			autoPurchase: true,
			is_decrypt: true,
			is_autoprint: true,
			phone_show:"",
			phone:"",
			phone_code:"",
			new_phone:"",
			old_pwd:"",
			new_pwd:"",
			new_pwd_confirm:"",
			is_get_phone_code:false,
			phone_second:60,
			is_phone_submit:false,
			is_pwd_submit:false,
			auto_print_order:'',
			decrypt_expire_time:'',
			decrypt_num:0,
			is_chongzhi: false,
			dialog:{
				decryptLogDialog:false,
				printProductDialog:false,
			},
			paymentCodeImg:'',
			paymentNum: '',
			orderPrintFee: '',
			is_payment: false,
			show_auto_print: 0,
			print_product_type: 1,
			print_product_ids: ''
		}
	},
	components:{
		myHead,myFoot,ucMenu,decryptLog
	},
	computed:{
		...mapState({
			users:(state)=>state.login.userinfo,
			
		}),
	},
	created(){
		
		this.init()
	},
	methods: {
		...mapActions({
			getUserinfoData:"ucenter/getUserinfoData",
			getCodeData:"login/getCodeData",
			doChangePhone:"ucenter/doChangePhone",
			doChangePwd:"ucenter/doChangePwd",
			doChangeRemind:"ucenter/doChangeRemind",
			doChangeAutoPurchase:"ucenter/doChangeAutoPurchase",
			doChangeDecrypt:"ucenter/doChangeDecrypt",
			doChangeAutoPrint:"ucenter/doChangeAutoPrint",
			getBaseConfig:"ucenter/getBaseConfig",
			getCheckPrintRecharge:"ucenter/getCheckPrintRecharge",
			opEditPrintProduct:"ucenter/opEditPrintProduct"
		}),
		init(){
			this.initConfig()
			this.initUserInfo()
		},
		initUserInfo(){
			this.getUserinfoData({data:{},success:(res)=>{
				if(res.code===200){
					// console.log('update userinfo success',res)
					this.phone = res.data.mobile
					this.phone_show = res.data.mobile.substr(0,3) + '****' + res.data.mobile.substr(7)
					this.switchTab = res.data.is_we_remind==1?true:false
					this.autoPurchase = res.data.auto_purchase==1?true:false
					this.is_decrypt = res.data.is_decrypt
					this.is_autoprint = res.data.auto_print_order
					this.decrypt_num = res.data.decrypt_num
					this.auto_print_order = res.data.auto_print_order
					this.decrypt_expire_time = res.data.decrypt_expire_time
					this.show_auto_print = res.data.show_auto_print
					this.print_product_type = res.data.print_product_type
					this.print_product_ids = res.data.print_product_ids
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		getPhoneCode(ev){//获取短信验证码
			let phone = this.phone
			if(!this.is_get_phone_code){
				this.is_get_phone_code = true
				this.getCodeData({data:{
						mobile:phone,
						event:ev,
					},success:(res)=>{
					if(res.code===200){
						// console.log('get phone code',res)
						this.$message({message: '发送成功~',type: 'success',});
						let timer = setInterval(()=>{
							this.phone_second -= 1
							if(this.phone_second == 0){
								clearInterval(timer)
								this.phone_second = 60
								this.is_get_phone_code = false
							}
						},1000)
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.is_get_phone_code = false
					}
				}})
			}
		},
		changePhone(){//修改手机号提交按钮
			if(!this.phone_code){
				this.$message({message: '请输入短信验证码',type: 'error',});
				return false
			}
			if(!this.new_phone){
				this.$message({message: '请输入新手机号码',type: 'error',});
				return false
			}
			if(!common._verify_mobile(this.new_phone)){
				this.$message({message: '请输入正确手机号',type: 'error',});
				return false
			}
			if(!this.is_phone_submit){
				this.is_phone_submit = true
				this.doChangePhone({data:{
						mobile:this.phone,
						code:this.phone_code,
						new_mobile:this.new_phone,
					},success:(res)=>{
					// console.log('change pwd success',res)
					if(res.code===200){
						this.$message({message: '修改成功~',type: 'success',});
						this.is_phone = false
						this.phone = this.new_phone
						this.phone_show = this.new_phone.substr(0,3) + '****' + this.new_phone.substr(7)
						this.is_get_phone_code = false
						this.phone_second = 60
						this.is_phone_submit = false
						this.phone_code = ""
						this.new_phone = ""
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.is_phone_submit = false
					}
				}})
			}
		},
		changePwd(){//修改密码提交按钮
			if(!this.old_pwd){
				this.$message({message: '请输入原密码',type: 'error',});
				return false
			}
			if(!this.new_pwd){
				this.$message({message: '请输入新密码',type: 'error',});
				return false
			}
			if(!this.new_pwd_confirm){
				this.$message({message: '请再次输入新密码',type: 'error',});
				return false
			}
			if(this.new_pwd != this.new_pwd_confirm){
				this.$message({message: '请确保两次输入的密码一致',type: 'error',});
				return false
			}
			if(!this.is_pwd_submit){
				this.is_pwd_submit = true
				this.doChangePwd({data:{
						password:this.old_pwd,
						new_password:this.new_pwd,
						true_password:this.new_pwd_confirm,
					},success:(res)=>{
					// console.log('change pwd success',res)
					if(res.code===200){
						this.$message({message: '修改成功~',type: 'success',});
						this.is_password = false
						this.old_pwd = ""
						this.new_pwd = ""
						this.new_pwd_confirm = ""
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.is_pwd_submit = false
					}
				}})
			}
		},
		switchChange(){//开关公众号提醒
			let type = this.switchTab?1:2
			this.doChangeRemind({data:{
					type:type,
				},success:(res)=>{
				// console.log('change pwd success',res)
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
			// console.log(this.switchTab,type)
		},
		autoPurchaseChange(){//开启自动采购
			let type = this.autoPurchase?1:0
			this.doChangeAutoPurchase({data:{
					type:type,
				},success:(res)=>{
				// console.log('change pwd success',res)
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
			// console.log(this.switchTab,type)
		},
		decryptSwitch(){//开关自动解密
			this.doChangeDecrypt({data:{
					is_decrypt:this.is_decrypt,
				},success:(res)=>{
				// console.log('change pwd success',res)
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		autoprintSwitch(){//开关自动打单
			this.doChangeAutoPrint({data:{
					type :this.is_autoprint,
				},success:(res)=>{
				// console.log('change pwd success',res)
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		
		showDecryptLog(){
			this.dialog.decryptLogDialog = true
		},
		initConfig(){
			this.getBaseConfig({data:{},success:(res)=>{
				// console.log('change pwd success',res)
				if(res.code===200){
					this.paymentCodeImg = res.data.recharge_print_zfb
					this.orderPrintFee = res.data.order_print_fee
				} 
			}})
		},
		bindRecharge(){//充值查询
			if(!this.paymentNum){
				this.$message({message: '请输入支付订单编号',type: 'error',});
				return false
			}
			if(!this.is_payment){
				this.is_payment = true
				this.getCheckPrintRecharge({data:{
					trade_no:this.paymentNum
				},success:(res)=>{
					this.is_payment = false
					if(res.code===200){
						this.$message({message: res.msg,type: 'success',});
						this.paymentNum = ''
						this.is_chongzhi = false
						this.initUserInfo()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
				}})
			}
		},
		//提交打单商品配置
		bindPrintProduct(){
			this.opEditPrintProduct({data:{
				print_product_type:this.print_product_type,
				print_product_ids:this.print_product_ids
			},success:(res)=>{
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
					this.initUserInfo()
					this.dialog.printProductDialog = false
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}
.right_con{width: 980px;background: #fff;}
.setting_main{padding: 36px 40px;}
.setiting_wrap{padding-top: 25px;}
.setting_line{margin-top: 30px;display: flex;font-size: 14px;line-height: 1;align-items: center;}
.setting_line .txt{width: 135px;text-align: right;}
.print_product{display: flex;flex-direction: column;align-items: flex-start;width: 100%;padding-top: 20px;}
.print_product .el-textarea{margin-top: 15px;}
.setting_line .con{flex: 1;width: 0;margin-left: 30px;display: flex;align-items: center;}
.setting_line .con .iconfont{font-size: 16px;color: #666666;margin-left: 10px;cursor: pointer;}
.phone_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.phone_mask.active{visibility: visible;opacity: 1;}
.phone_mask .phone_main{width: 400px;height: 312px;box-sizing: border-box;padding: 36px 0 28px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.phone_mask.active .phone_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.phone_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.phone_main .title{margin: 0 32px;font-size: 20px;line-height: 1;}
.phone_main .input_line{margin: 24px 32px 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.phone_main .input_line .txt{width: 90px;font-size: 12px;color: #999999;}
.phone_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.phone_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.phone_main .tips{margin: 10px 32px 0;line-height: 1;font-size: 12px;color: #666666;}
.phone_main .btn_wrap{margin: 32px 32px 0;display: flex;justify-content: space-between;}
.phone_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.phone_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.phone_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.password_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.password_mask.active{visibility: visible;opacity: 1;}
.password_mask .password_main{width: 400px;height: 312px;box-sizing: border-box;padding: 36px 0 28px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.password_mask.active .password_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.password_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.password_main .title{margin: 0 32px 15px;font-size: 20px;line-height: 1;}
.password_main .input_line{margin: 10px 32px 0;height: 36px;box-sizing: border-box;border: 1px solid #ddd;border-radius: 4px;display: flex;padding: 0 10px;align-items: center;}
.password_main .input_line .txt{width: 76px;font-size: 12px;color: #999999;}
.password_main .input_line .in{flex: 1;width: 0;font-size: 12px;color: #333;}
.password_main .input_line .getcode{padding-left: 8px;border-left: 1px solid #eee;font-size: 12px;color: #333333;cursor: pointer;line-height: 1;}
.password_main .tips{margin: 10px 32px 0;line-height: 1;font-size: 12px;color: #666666;}
.password_main .btn_wrap{margin: 32px 32px 0;display: flex;justify-content: space-between;}
.password_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.password_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.password_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.btn_log{color: #f0413e;margin-left: 20px;cursor: pointer;}

.chongzhi_main{box-sizing: border-box;background: #fff;}
.chongzhi_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.chongzhi_main .title{display: flex;align-items: center;margin: 0 22px;font-size: 20px;line-height: 1;}
.chongzhi_main .title .icon{width: 22px;height: 22px;margin-right: 8px;}
.chongzhi_main .input_line{margin: 15px 22px 0;display: flex;align-items: center;}
.chongzhi_main .input_line .txt{font-size: 12px;color: #666666;margin-right: 10px;}
.chongzhi_main .input_line .in{width: auto;}
.chongzhi_main .code{width: 155px;height: auto;display: block;margin: 30px auto 0;}
.chongzhi_main .btn_wrap{margin: 45px 0 0;display: flex;justify-content: space-between;}
.chongzhi_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.chongzhi_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.chongzhi_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.chongzhi_main .explain{color: #f0413e;font-size: 14px;font-weight: bold;}

</style>
<style>
.el_input{display: flex;margin: 14px 32px 0;width: initial;}
.el_input .el-input__inner{height: 38px;line-height: 38px;padding-left: 58px;flex: 1;width: 0;}
.el_input .el-input__inner:focus{border-color: var(--red);}
.el_input.el_in2 .el-input__inner{padding-left: 110px;}
.el_input .el-input__prefix{left: 2px;top: 1px}
.el_input .el-input__prefix .icons{width: 38px;height: 36px;display: flex;justify-content: center;align-items: center;top: 1px;left: 2px;background: #f5f5f5;border-right: 1px solid #ddd;border-radius: 4px 0 0 4px;}
.el_input .el-input__prefix .icons .iconfont{font-size: 20px;color: #cccccc;}
.el_input .el-input__prefix .txts{width: 100%;height: 100%;display: flex;align-items: center;padding-left: 10px;}
.el_input .el-input__suffix{display: flex;}
.el_input .el-input__suffix .el-input__suffix-inner{display: flex;align-items: center;}
.el_input .el-input__suffix .el-input__suffix-inner .getcode{font-size: 14px;color: #333333;padding: 0 12px;border-left: 1px solid #ccc;line-height: 1;cursor: pointer;}

</style>
