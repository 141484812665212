<template>
	<div class="opts_wrap" v-loading="loading">
		<div class="list_wrap">
			<el-table
			    :data="list"
			    style="width: 100%"
			    height="450">
			    <el-table-column
			      prop="type_text"
			      label="类型"
			      width="200">
			    </el-table-column>
			    <el-table-column
			      prop="change_num"
			      label="数量"
			      width="200">
			    </el-table-column>
			    <el-table-column
			      prop="after_num"
			      label="变更后"
			      width="200">
			    </el-table-column>
			    <el-table-column
			      prop="msg"
			      label="描述"
			      width="">
			    </el-table-column>
					<el-table-column
					  prop="scope"
					  label="操作"
					  width="150">
						<template slot-scope="scope">
							<el-link type="primary" v-if="scope.row.file_path" @click="download(scope.row.file_path)">下载</el-link>
						</template>
					</el-table-column>
					<el-table-column
					  prop="create_time"
					  label="时间"
					  width="200">
					</el-table-column>
			  </el-table>

		</div>
		
		<div class="turn_page" v-if="page_data.total > 0">
			<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit" @pagination="getList" />
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Pagination from '../../components/pagination/pagination';
import { mapActions,mapState,mapMutations } from "vuex";
import config from "../../assets/js/conf/config";
export default {
	name: "decryptLog",
	components:{
		Pagination
	},
	props: {
		isLoading: {
			type: Boolean,
			default: false
		},
		show: {
			type: Boolean,
			default: false
		},
		info: {
			type: Object,
			default: function(){
				return {}
			}
		},
	},
	data() {
		return {
			loading: false,
			list:[],
			page_data: {
				limit: 10,
				page: 1,
				total:0,
			},
			searchData: {},
			rules: {
			}
		};
	},
	created(){
	},
	mounted() {
		this.init()
	},
	
	computed:{
		...mapState({
			
		}),
	},
	watch:{
		show(val) {
			if(val){
				this.init()
			}
		}
	},
	
	methods: {
		...mapActions({
			getDecryptLog:"ucenter/getDecryptLog",
		}),
		init(){
			if(this.isLoading){
				this.loading = true
			}
			this.page_data.page = 1
			this.page_data.total = 0
			this.getList()
		},
		getList(){
			this.loading = true
			let param = {limit:this.page_data.limit,page:this.page_data.page}
			Object.assign(param, this.searchData)
			this.getDecryptLog({data:param,success:(res)=>{
				if(res.code===200){
					console.log('get log list',res)
					this.list = res.data.data
					this.page_data.total = res.data.total
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.loading = false
			}})
		},
		cancel(){
			this.$emit('close', {})
		},
		download(url){
			window.open(url) 
		},
		
	},

}
</script>

<style scoped>
	.opts_wrap{padding-top: 14px;width: 100%;}
	.opts_wrap .list_wrap{width: 100%;}
	
	
	.opts_wrap .btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;margin-right: 14px;}
	.opts_wrap .btn_wrap .btn:nth-child(2){background: #fff;color: #333;border: 1px solid #ddd;margin-right: 14px;}
	.ml8{margin-left: 8px;}
	.opts_wrap .btn_wrap .btn:nth-child(3){background: #fff;color: #333;border: 1px solid #ddd;}
	.w80{width: 80px;}
	
	
	.order_records{margin-top: 15px;width: 100%;height: auto;box-sizing: border-box;padding: 0 40px 30px;background: #fff;line-height: 1;}
	.records_wrap{margin-top: 25px;}
	.records_wrap .record_con{padding-left: 42px;position: relative;}
	.record_con::before{width: 32px;height: 32px;border-radius: 50%;display: flex;justify-content: center;align-items: center;position: absolute;top: 0;left: 0;z-index: 2;font-size: 12px;font-weight: bold}
	.record_con.c{padding-bottom: 13px;}
	.record_con.c::before{content: '买家';background: #ffecee;color: var(--red);}
	.record_con.b::before{content: '商家';background: #f0ffee;color: #0e9f1f;}
	.record_con.c::after{content: '';width: 2px;height: 68%;background: #eee;position: absolute;left: 15px;top: 40px;}
	.record_con .txt{font-size: 14px;line-height: 32px;}
	.record_con .txt_wrap{margin-top: 12px;width: 100%;font-size: 12px;}
	.record_con .txt_wrap .red{color: var(--red);}
	
	/deep/.el-input--suffix.is-focus .el-input__inner{border-color: var(--red) !important;}
	/*red border color*/
	/deep/.el-textarea__inner:focus,.el-textarea__inner:hover, .el-input__inner:focus ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus ,.el-select .el-input__inner:hover,.el-radio__input.is-checked .el-radio__inner ,.el-radio__inner:hover,.el-radio.is-bordered.is-checked{border-color: var(--red) !important;}
	
	/*red color*/
	/deep/.el-select-dropdown__item.selected ,.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	
	/*red background color*/
	.el-radio__input.is-checked .el-radio__inner{background-color: var(--red);}
	.el-radio{margin-right:10px;}
	.el-descriptions-item__container{align-item:center;}
	
	

</style>
<style>
	.el-select-dropdown__item.selected{color: var(--red) !important;}
	.el-select .el-input__inner:focus{border-color: var(--red) !important;}
	.el-select .el-input.is-focus .el-input__inner{border-color: var(--red) !important;}
	.el-radio__input.is-checked+.el-radio__label{color: var(--red);}
	.el-radio__input.is-checked .el-radio__inner{border-color:soid var(--red);background:var(--red);border:1px solid var(--red);}
	
	.el-descriptions{font-size:12px;}
	.la_style{color: #999;width: 80px !important;}
</style>
